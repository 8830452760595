import { useCallback, useEffect, useRef, useState } from 'react';
import { values } from 'lodash';

export type PageEvent = {
    anonymousId: string | null;
    name: string | null;
    startTimestamp: string | null;
    path: string | null;
    url: string | null;
    title: string | null;
    referrer: string | null;
    search: string | null;
} & PageEventProperties;

export type PageEventProperties = {
    address?: string;
    chain?: string;
    tokenAddress?: string;
    tokenId?: string;
};

export default () => {
    // As we use event listeners we access data via the ref
    const currentEventRef = useRef({} as PageEvent);

    const setCurrentEvent = (data: any) => {
        currentEventRef.current = data;
    };

    const [hasEventStarted, setHasEventStarted] = useState(false);

    useEffect(() => {
        setHasEventStarted(
            !!currentEventRef.current?.startTimestamp &&
                !!currentEventRef.current?.name
        );
    }, [currentEventRef.current]);

    const trackPageStart = useCallback(
        (name: string, properties?: PageEventProperties) => {
            // Already tracking something so end/track that
            if (hasEventStarted) trackPageEnd();

            // Only track once all properties have a value
            if (values(properties).filter((value) => !value)?.length > 0) {
                return;
            }

            setCurrentEvent({
                ...properties,
                anonymousId:
                    // @ts-ignore
                    window?.analytics?.user && window.analytics.user().anonymousId(),
                name,
                startTimestamp: new Date().toISOString(),
                path: location.pathname,
                url: location.href,
                title: document.title,
                referrer: document.referrer,
                search: location.search,
            });
        },
        [currentEventRef?.current]
    );

    const getTrackPageEndEvent = useCallback(() => {
        if (
            !currentEventRef?.current?.startTimestamp ||
            !currentEventRef?.current?.name
        )
            return;
        // @ts-ignore
        if (window?.analytics) {
            const endTimestamp = new Date().toISOString();

            const durationSeconds =
                (new Date(endTimestamp).getTime() -
                    new Date(
                        currentEventRef?.current?.startTimestamp
                    ).getTime()) /
                1000;

            return {
                endTimestamp,
                durationSeconds,
                startTimestamp: currentEventRef?.current?.startTimestamp,
                path: currentEventRef?.current?.path,
                url: currentEventRef?.current?.url,
                title: currentEventRef?.current?.title,
                referrer: currentEventRef?.current?.referrer,
                search: currentEventRef?.current?.search,

                // Optional properties, specifically here to prevent easy override mistakes
                address: currentEventRef.current?.address,
                chain: currentEventRef.current?.chain,
                tokenAddress: currentEventRef.current?.tokenAddress,
                tokenId: currentEventRef.current?.tokenId,
            };
        }
    }, [currentEventRef?.current]);

    const trackPageEnd = useCallback(() => {
        const trackPageEvent = getTrackPageEndEvent();
        if (!trackPageEvent) return;
        // @ts-ignore
        if (window?.analytics) {
            // @ts-ignore
            window.analytics.page(currentEventRef.current.name, {
                ...trackPageEvent,
            });
            setCurrentEvent({} as PageEvent);
        }
    }, [currentEventRef?.current]);

    return {
        trackPageStart,
        trackPageEnd,
        currentEventRef,
        hasEventStarted,
    };
};
