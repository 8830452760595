exports.components = {
  "component---src-pages-collection-tsx": () => import("./../../../src/pages/collection.tsx" /* webpackChunkName: "component---src-pages-collection-tsx" */),
  "component---src-pages-connect-tsx": () => import("./../../../src/pages/connect.tsx" /* webpackChunkName: "component---src-pages-connect-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mint-complete-tsx": () => import("./../../../src/pages/mint-complete.tsx" /* webpackChunkName: "component---src-pages-mint-complete-tsx" */),
  "component---src-pages-mint-tsx": () => import("./../../../src/pages/mint.tsx" /* webpackChunkName: "component---src-pages-mint-tsx" */),
  "component---src-pages-page-index-tsx": () => import("./../../../src/pages/page/index.tsx" /* webpackChunkName: "component---src-pages-page-index-tsx" */),
  "component---src-pages-token-tsx": () => import("./../../../src/pages/token.tsx" /* webpackChunkName: "component---src-pages-token-tsx" */)
}

