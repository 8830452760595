import React from 'react';
import { Toaster } from 'react-hot-toast';
import ReactTooltip from 'react-tooltip';
import { Helmet } from 'react-helmet';

export default function RootContainer({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <>
            <Helmet>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1"
                />
            </Helmet>
            {children}
            <Toaster />
            <ReactTooltip />
        </>
    );
}
