import React, { createContext, useEffect } from 'react';
import useTrackPage from '../useTrackPage';
import { globalHistory } from '@reach/router';

type ContextType = {
    hasEventStarted: boolean;
    trackPageStart: (
        name: string,
        properties?: { address?: string }
    ) => void;
    trackPageEnd: () => void;
    currentEventRef: { current: any };
};

export const G3lyticsContext = createContext({} as ContextType);

const G3lyticsProvider = ({ children }: { children: React.ReactNode }) => {
    const { hasEventStarted, trackPageStart, trackPageEnd, currentEventRef } =
        useTrackPage();

    useEffect(() => {
        return globalHistory.listen((data: any) => {
            // Detect page change
            trackPageEnd();
        });
    }, []);

    return (
        <G3lyticsContext.Provider
            value={{
                hasEventStarted,
                trackPageStart,
                trackPageEnd,
                currentEventRef,
            }}
        >
            {children}
        </G3lyticsContext.Provider>
    );
};

export default G3lyticsProvider;
