import React, { useEffect } from 'react';

export default ({ children }: { children: any }) => {
    // const { isWeb3Enabled, enableWeb3 } = useMoralis();

    useEffect(() => {
        // Moralis.setEnableWeb3(CoinbaseConnector);
    }, []);
    return <>{children}</>;
};
