import '@fontsource/poppins';
import React from 'react';
import RootContainer from './src/components/base/RootContainer.tsx';
import { MoralisProvider } from 'react-moralis';
import App from './src/App';
import apolloClient from './api/client';
import { ApolloProvider } from '@apollo/client';
import G3lyticsProvider from './src/g3lytics/contexts/G3lyticsProvider';

export const wrapRootElement = ({ element }) => (
    <MoralisProvider
        appId={process.env.GATSBY_MORALIS_APP_ID}
        serverUrl={process.env.GATSBY_MORALIS_SERVER_URL}
    >
        <ApolloProvider client={apolloClient}>
            <App>
                <G3lyticsProvider>
                    <RootContainer>{element}</RootContainer>
                </G3lyticsProvider>
            </App>
        </ApolloProvider>
    </MoralisProvider>
);

export const shouldUpdateScroll = () => false;
