import { ApolloClient, InMemoryCache } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import clientFetch from 'unfetch';
import serverFetch, { Headers as ServerHeaders } from 'node-fetch';

const isBrowser = typeof document !== 'undefined';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
global.Headers = isBrowser ? global.Headers : ServerHeaders;
const customFetch = isBrowser ? clientFetch : serverFetch;

const GENVERSE_API_BASE_URL = process.env.GATSBY_METAPAGE_API;
const restLink = new RestLink({
    uri: GENVERSE_API_BASE_URL,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    customFetch,
});

const client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            GenverseToken: {
                keyFields: ['chain', 'tokenAddress', 'tokenId'],
            },
        },
    }),
    link: restLink,
});

export default client;
