import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints, mode } from '@chakra-ui/theme-tools';

const config = {
    initialColorMode: 'dark',
    // useSystemColorMode: true,
};

const breakpoints = createBreakpoints({
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
});

const theme = {
    config,
    breakpoints,
    fonts: {
        heading: 'Poppins, sans-serif',
        body: 'Poppins, sans-serif',
    },
    colors: {
        primary: '#AB2FB7',
        secondary: '#FFFFFF',
        tertiary: '#dc5eca',
        // fourtiary??: #AB2FB7
        // fivetiary??: #C24138
        // sixtiary??: #C9F70A
    },
    components: {
        Input: {
            baseStyle: {
                _focus: { boxShadow: 'none' },
            },
            variants: {
                outline: (props: any) => ({
                    field: {
                        // bg: mode('white', 'gray.800')(props),
                        // borderColor: 'gray.300',
                        _placeholder: {
                            color: mode(
                                'rgba(0, 0, 0, .7)',
                                'rgba(255, 255, 255, .5)'
                            )(props),
                        },
                    },
                }),
            },
        },
        Checkbox: {
            baseStyle: (props: any) => ({
                control: {
                    bg: mode('rgba(0, 0, 0, 0.1)', 'transparent')(props),
                },
            }),
        },
    },
    styles: {
        global: (props: any) => ({
            body: {
                width: '100vw',
                margin: 0,
                bg: mode('white', 'black')(props),
                color: mode('black', 'white')(props),
            },

            button: {
                color: 'black',
                _hover: {
                    background: 'rgba(49, 41, 138, 0.5) !important',
                    color: 'rgba(255, 255, 255, 1) !important',
                },
            },

            canvas: {
                display: 'block',
                // position: 'absolute',
                // height: '100%',
                // width: '100%',
                // top: 0,
                // bottom: 0,
                // left: 0,
                // right: 0,
                // zIndex: -1
            },
        }),
    },
};

export default extendTheme(theme);
